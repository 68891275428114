import api from './api';

const getAccessToken = () => localStorage.getItem('access_token');
const getRefreshToken = () => localStorage.getItem('refresh_token');

const refreshToken = async () => {
    try {
        const response = await api.post('/api/token/refresh/', {
            refresh: getRefreshToken()
        });
        localStorage.setItem('access_token', response.data.access);
        return response.data.access;
    } catch (error) {
        console.error('Error refreshing token:', error);
        throw error;
    }
};

const logout = async () => {
    try {
        const refreshToken = getRefreshToken();
        await api.post('/api/auth/logout/', { refresh_token: refreshToken });
    } catch (error) {
        console.error('Error during logout:', error);
    } finally {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
    }
};

export { getAccessToken, getRefreshToken, refreshToken, logout };