// frontend/src/components/ErrorBoundary.js

import React from 'react';
import PropTypes from 'prop-types';
import { AlertCircle } from 'lucide-react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Uncaught error:', error, errorInfo);
    this.setState({ error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
          <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
            <div className="flex items-center mb-6">
              <AlertCircle className="text-red-500 mr-3" size={24} />
              <h1 className="text-2xl font-bold text-gray-800">Oops! Something went wrong.</h1>
            </div>
            <p className="text-gray-600 mb-4">
              We&apos;re sorry, but an unexpected error occurred. Our team has been notified and
              we&apos;re working to fix the issue.
            </p>
            <p className="text-gray-600 mb-6">Please try refreshing the page or come back later.</p>
            <button
              onClick={() => window.location.reload()}
              className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-200"
            >
              Refresh Page
            </button>
            {this.state.error && (
              <details className="mt-4 p-4 bg-gray-100 rounded">
                <summary className="text-sm text-gray-700 cursor-pointer">Error Details</summary>
                <pre className="mt-2 text-xs text-red-500 overflow-auto">
                  {this.state.error.toString()}
                  <br />
                  {this.state.errorInfo.componentStack}
                </pre>
              </details>
            )}
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
