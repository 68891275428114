// frontend/src/contexts/AuthContext.js

import React, { createContext, useState, useContext, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import api from '../services/api';
import { logout as authLogout } from '../services/authService';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const checkAuthStatus = useCallback(async () => {
    console.log('AuthContext: Checking auth status');
    try {
      const token = localStorage.getItem('access_token');
      console.log('AuthContext: Token exists:', !!token);

      if (!token) {
        console.warn('AuthContext: No token found, setting unauthenticated');
        setIsAuthenticated(false);
        setUser(null);
        setLoading(false);
        return;
      }

      const response = await api.getAuthStatus();
      console.log('AuthContext: Auth status response:', response);

      if (response && response.data) {
        console.log('AuthContext: Setting authenticated state');
        setIsAuthenticated(response.data.isAuthenticated);
        setUser(response.data.user || null);
      } else {
        console.error('AuthContext: Invalid response from server');
        throw new Error('Invalid response from server');
      }

      if (response.data.isAuthenticated) {
        console.log('AuthContext: User authenticated, setting Authorization header');
        api.axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      } else {
        console.warn('AuthContext: User not authenticated, removing token');
        localStorage.removeItem('access_token');
        delete api.axiosInstance.defaults.headers.common['Authorization'];
      }
    } catch (error) {
      console.error('AuthContext: Error checking auth status:', error.message);
      localStorage.removeItem('access_token');
      setIsAuthenticated(false);
      setUser(null);
      delete api.axiosInstance.defaults.headers.common['Authorization'];
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    console.log('AuthContext: Component mounted, checking auth status');
    checkAuthStatus();
  }, [checkAuthStatus]);

  const login = useCallback(async (accessToken, refreshToken) => {
    console.log('AuthContext: Logging in with new tokens');
    localStorage.setItem('access_token', accessToken);
    localStorage.setItem('refresh_token', refreshToken);
    setIsAuthenticated(true);
    api.axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

    try {
      const response = await api.getAuthStatus();
      setUser(response.data.user);
      console.log('AuthContext: User data set after login:', response.data.user);
    } catch (error) {
      console.error('Error fetching user data after login:', error);
    }
  }, []);

  const refreshUserData = useCallback(async () => {
    console.log('AuthContext: Refreshing user data');
    try {
      const response = await api.getAuthStatus();
      console.log('AuthContext: Refresh user data response:', response);
      if (response.data.isAuthenticated) {
        setIsAuthenticated(true);
        setUser(response.data.user);
        console.log('AuthContext: User data refreshed and authenticated');
      } else {
        console.log('AuthContext: User not authenticated after refresh');
        setIsAuthenticated(false);
        setUser(null);
      }
    } catch (error) {
      console.error('AuthContext: Error refreshing user data:', error);
    }
  }, []);

  const logout = useCallback(async () => {
    console.log('AuthContext: Attempting logout');
    try {
      await authLogout();
      setIsAuthenticated(false);
      setUser(null);
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      delete api.axiosInstance.defaults.headers.common['Authorization'];
      console.log('AuthContext: Logout successful');
    } catch (error) {
      console.error('AuthContext: Logout error:', error);
    }
  }, []);

  const updateUserCredits = useCallback((newCredits) => {
    setUser((prevUser) =>
      prevUser
        ? {
            ...prevUser,
            credits: newCredits,
          }
        : null
    );
  }, []);

  console.log('AuthContext: Current state:', { isAuthenticated, user, loading });

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        login,
        logout,
        checkAuthStatus,
        updateUserCredits,
        refreshUserData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    console.error('useAuth must be used within an AuthProvider');
    return {
      isAuthenticated: false,
      user: null,
      loading: false,
      login: () => {},
      logout: () => {},
      checkAuthStatus: () => {},
      updateUserCredits: () => {},
      refreshUserData: () => {},
    };
  }
  return context;
};

export default AuthContext;
