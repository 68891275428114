// frontend/src/services/api.js

import axios from 'axios';

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'https://app.godog.io',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

console.log('API Base URL:', axiosInstance.defaults.baseURL);

axiosInstance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("access_token");
    if (token) {
      console.log("API Request: Adding Authorization header");
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    console.log("API Request Config:", config);
    return config;
  },
  function (error) {
    console.error("API Request Error:", error);
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => {
    // Parse dates in the response
    const parseDates = (obj) => {
      for (let key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          if (
            typeof obj[key] === 'string' &&
            /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/.test(obj[key])
          ) {
            obj[key] = new Date(obj[key]);
          } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            parseDates(obj[key]);
          }
        }
      }
    };

    if (response.data) {
      parseDates(response.data);
    }
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshToken = localStorage.getItem('refresh_token');
        const response = await axiosInstance.post('/api/token/refresh/', { refresh: refreshToken });
        localStorage.setItem('access_token', response.data.access);
        axiosInstance.defaults.headers['Authorization'] = `Bearer ${response.data.access}`;
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        window.location.href = '/login';
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export const sendLoginCode = async (email) => {
  console.log('Sending login code for email:', email);
  try {
    const response = await axiosInstance.post('/api/auth/send-code/', { email });
    console.log('Send code response:', response);
    return response;
  } catch (error) {
    console.error('Error sending login code:', error);
    if (error.response) {
      console.error('Error response:', error.response);
      return error.response;
    }
    // If there's no error.response, return a custom error response
    return {
      status: 500,
      data: { error: 'An unexpected error occurred' },
    };
  }
};

export const verifyLoginCode = async (email, code) => {
  try {
    const response = await axiosInstance.post('/api/auth/verify-code/', { email, code });
    return response;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      throw new Error('No response received from server');
    } else {
      throw error;
    }
  }
};

export const getAuthStatus = () => axiosInstance.get('/api/auth/status/');
export const getDashboardData = () => axiosInstance.get('/api/dashboard/');
export const logout = () => axiosInstance.post('/api/auth/logout/');
export const getRentalOptions = () => axiosInstance.get('/api/rental-options/');
export const rentPhoneNumber = (data) => axiosInstance.post('/api/rent-phone-number/', data);
export const getSMSInbox = (phoneNumber) => axiosInstance.get(`/api/sms-inbox/${phoneNumber}/`);
export const getAvailableNumbers = (country, areaCode) =>
  axiosInstance.get(`/api/available-numbers/?country=${country}&area_code=${areaCode}`);
export const initiatePurchase = (data) => axiosInstance.post('/api/initiate-purchase/', data);

export const verifyPayment = (sessionId) =>
  axiosInstance.get(`/api/payment-success/?session_id=${sessionId}`);
export const getLatestMessages = () => axiosInstance.get('/api/messages/latest/');
export const getAllMessages = () => axiosInstance.get('/api/messages/');
export const getProfile = () => axiosInstance.get('/api/profile/');
export const exportData = () => axiosInstance.post('/api/profile/', { action: 'export_data' });


const apiService = {
  sendLoginCode,
  verifyLoginCode,
  getAuthStatus,
  getDashboardData,
  logout,
  getRentalOptions,
  rentPhoneNumber,
  getSMSInbox,
  getAvailableNumbers,
  initiatePurchase,
  verifyPayment,
  getLatestMessages,
  getAllMessages,
  getProfile,
  exportData,
};

export default apiService;
