// frontend/src/App.js

import React, { Suspense, lazy } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';
import LoadingSpinner from './components/LoadingSpinner';
import PrivateRoute from './components/PrivateRoute';
import ErrorBoundary from './components/ErrorBoundary';

const Login = lazy(() => import('./components/Login'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const SMSInbox = lazy(() => import('./pages/SMSInbox'));
const PurchaseCredits = lazy(() => import('./pages/PurchaseCredits'));
const PaymentSuccess = lazy(() => import('./pages/PaymentSuccess')); 
const RentPhoneNumber = lazy(() => import('./pages/RentPhoneNumber'));
const Profile = lazy(() => import('./pages/Profile'));

function App() {
  const { isAuthenticated, loading } = useAuth();

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <ErrorBoundary>
      <div className="min-h-screen bg-gray-100">
        <Suspense fallback={<LoadingSpinner />}>
          <Routes>
            <Route
              path="/login"
              element={isAuthenticated ? <Navigate to="/dashboard" /> : <Login />}
            />
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/sms-inbox/:phoneNumber?"
              element={
                <PrivateRoute>
                  <SMSInbox />
                </PrivateRoute>
              }
            />
            <Route
              path="/purchase-credits"
              element={
                <PrivateRoute>
                  <PurchaseCredits />
                </PrivateRoute>
              }
            />
            <Route path="/payment-success" element={<PaymentSuccess />} />
            <Route
              path="/rent-phone-number"
              element={
                <PrivateRoute>
                  <RentPhoneNumber />
                </PrivateRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <PrivateRoute>
                  <Profile />
                </PrivateRoute>
              }
            />
            <Route path="/" element={isAuthenticated ? <Navigate to="/dashboard" /> : <Login />} />
          </Routes>
        </Suspense>
      </div>
    </ErrorBoundary>
  );
}

export default App;
