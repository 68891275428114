import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'lucide-react';

const LoadingSpinner = ({ size = 'default', color = 'primary' }) => {
  const sizeClasses = {
    small: 'w-4 h-4',
    default: 'w-8 h-8',
    large: 'w-12 h-12',
  };

  const colorClasses = {
    primary: 'text-primary-600',
    white: 'text-white',
  };

  return (
    <div className="flex justify-center items-center">
      <Loader className={`animate-spin ${sizeClasses[size]} ${colorClasses[color]}`} />
    </div>
  );
};

LoadingSpinner.propTypes = {
  size: PropTypes.oneOf(['small', 'default', 'large']),
  color: PropTypes.oneOf(['primary', 'white']),
};

export default LoadingSpinner;
